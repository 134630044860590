import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Avatar, Box, Collapse, Grid, IconButton, Paper, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { forwardRef, useContext, useEffect, useState } from "react";
import TriangleIcon from '../../../icons/Triangle';
import WidgetTextField from '../WidgetTextField';
import { ClaimContext } from '../../../utlis/ClaimContext';

const OverviewWidget = ({ obj }) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const [collapse, setCollapse] = useState(true);
    const [discrepancy, setDiscrepancy] = useState(false);

    const { claim } = useContext(ClaimContext);

    useEffect(() => {
        const hasDiscrepancy = Object.values(obj?.data).filter(o => !o?.bool)?.length >= 1;
        if (hasDiscrepancy) {
            setDiscrepancy(true);
        }
    }, [obj?.data])

    const handleCollapse = () => setCollapse(!collapse);

    const Success = () => {
        return (
            < Avatar sx={{ height: 20, width: 20, bgcolor: "success.main", ml: .5 }}>
                <CheckIcon sx={{ color: "#fff", fontSize: "16px" }} />
            </Avatar >
        );
    };

    const Error = () => {
        return (
            <Avatar sx={{ height: 20, width: 20, bgcolor: "error.main", ml: .5 }}>
                <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} />
            </Avatar>
        );
    };

    const TextField = ({ value }) => {
        return <WidgetTextField
            color="widget"
            value={value?.api ?? ""}
            variant="standard"
            fullWidth
            focused
            multiline
            InputLabelProps={{
                sx: { px: 1 }
            }}
            InputProps={{
                readOnly: true,
                disableUnderline: true,
                endAdornment: value?.bool ? <Success /> : <Error />,
                sx: {
                    pl: 1,
                    pr: belowsm ? 2 : 4,
                    color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"
                }
            }}
        />
    };

    const SkeletonTextField = () => {
        return <WidgetTextField
            color="widget"
            variant="standard"
            fullWidth
            focused
            multiline
            InputLabelProps={{
                sx: { px: 1 }
            }}
            InputProps={{
                inputComponent: forwardRef((props, ref) => { return <Skeleton variant="text" animation="wave" sx={{ width: "100%", pb: .1, mr: 1 }} /> }),
                readOnly: true,
                disableUnderline: true,
                endAdornment: <Skeleton variant="circular" animation="wave" width={20} height={20} />,
                sx: {
                    pl: 1,
                    pr: belowsm ? 2 : 4,
                    color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"
                }
            }}
        />
    };

    return (
        <Grid item xs={12} sx={{ display: "block" }}>
            <Paper
                elevation={2}
                sx={{
                    pt: 1,
                    pb: 1,
                    background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                    borderRadius: "14.362px",
                    borderColor: discrepancy ? "error.main" : "success.main",
                    boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                }}
            >
                <Grid container
                    sx={{
                        px: 1,
                        pb: collapse ? 1 : 0,
                        borderBottom: collapse ? 1 : 0,
                        borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                    }}
                >
                    <Grid item xs={11} sx={{ display: "flex", flexDirection: "row", pr: 2 }}>
                        <Typography
                            noWrap
                            sx={{
                                color: theme.palette.widget.main,
                                fontSize: belowsm ? "20px" : "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                mt: belowsm ? .75 : .5,
                                pr: belowsm ? .5 : 1
                            }}
                        >
                            {obj?.title ?? ""}
                        </Typography>
                        <Box sx={{ mt: 1, mr: 1 }}>
                            {claim?.currentStage === "FIRST_STAGE" ? <Skeleton variant="circular" animation="wave" width={20} height={20} sx={{ ml: .5 }} /> : discrepancy ? <Error /> : <Success />}
                        </Box>
                    </Grid>
                    <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", pr: belowsm ? 0 : 2 }}>
                        <IconButton
                            onClick={handleCollapse}
                            sx={{
                                transform: collapse ? 'rotate(0deg)' : 'rotate(180deg)',
                                transition: 'transform 250ms ease-in-out',
                            }}>
                            <TriangleIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Collapse
                    in={collapse}
                    mountOnEnter
                >
                    <Box sx={{ mt: 1 }}>
                        {claim?.currentStage === "FIRST_STAGE" ?
                            Object.entries(obj?.data ?? {}).map(([key, value]) => { return <SkeletonTextField key={`SkeletonTextField-${key}`} value={value} /> }) :
                            Object.entries(obj?.data ?? {}).map(([key, value]) => { return <TextField key={`TextField-${key}`} value={value} /> })
                        }
                    </Box>
                </Collapse>
            </Paper>
        </Grid>
    )
};

export default OverviewWidget;