// import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import App from './App';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const keycloak = {
    client_id: window.env.CLIENT_ID,
    authority: window.env.AUTH_URL,
    redirect_uri: `${window.location.origin}/login`,
    automaticSilentRenew: true,
    scope: 'openid email profile',
    onSigninCallback: async (user) => {
        if (user) {
            window.history.replaceState({}, document.title, window.location.pathname);
        };
    }
};

root.render(
    <AuthProvider {...keycloak}>
        <App />
    </AuthProvider>
);

reportWebVitals();