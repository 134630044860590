import { CircularProgress, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Alert = forwardRef(function Alert(props, ref) {
    if (props.severity === "info") {
        return <MuiAlert elevation={2} icon={<CircularProgress color="inherit" size={16} sx={{ mt: .25 }} />} ref={ref} {...props} />;
    };
    return <MuiAlert elevation={2} ref={ref} {...props} />;
});

const SnackBar = forwardRef((props, ref) => {

    const theme = useTheme();
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState({});
    const [stage, setStage] = useState(null);

    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
        completed(currentStage) {
            setMessage({
                severity: "success",
                message: getCurrentStageText(currentStage)
            });
            setStage(currentStage);
            setOpen(true);
        },
        stage(currentStage) {
            setMessage({
                severity: "info",
                message: getCurrentStageText(currentStage)
            });
            setStage(currentStage);
            setOpen(true);
        },
        failed(currentStage) {
            setMessage({
                severity: "error",
                message: getCurrentStageText(currentStage)
            });
            setStage(currentStage);
            setOpen(true);
        }
    }));

    const getCurrentStageText = (currentStage) => {
        switch (currentStage) {
            case null:
                return t("processingClaimStage", { stage: 0 });
            case "FIRST_STAGE":
                return t("processingClaimStage", { stage: 1 });
            case "SECOND_STAGE":
                return t("processingClaimStage", { stage: 2 });
            case "THIRD_STAGE":
                return t("processingClaimStage", { stage: 3 });
            case "FOURTH_STAGE":
                return t("processingClaimStage", { stage: 4 });
            case "FIFTH_STAGE":
                return t("processingClaimStage", { stage: 5 });
            case "SUMMARIZE":
                return t("processingClaimSummarize");
            case "COMPLETED":
                return t("processingClaimCompleted");
            case "FAILED":
                return t("processingClaimFailed");
            default:
                return t("processingClaimDefault");
        };
    };

    const handleClose = () => {
        if (stage === "COMPLETED" || stage === "FAILED") {
            setOpen(false);
        };
    };

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: belowsm ? "center" : "right" }}
            sx={{
                mt: belowsm ? 21.25 : belowmd ? 15.25 : 8.25,
                mr: belowsm ? 0 : -1,
                width: belowsm ? "92vw" : "auto",
                left: belowsm ? 16 : 8,
                right: belowsm ? 16 : 8
            }}
        >
            <Alert severity={message.severity} sx={{ width: "100%", justifyContent: "center" }}>{message.message}</Alert>
        </Snackbar>
    )
});

export default SnackBar;