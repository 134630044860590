import { Backdrop, Box, CircularProgress, Collapse, Container, Grid, IconButton, List, ListItemButton, ListItemText, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isEqual } from "lodash";
import moment from "moment";
import { Fragment, memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth, withAuthenticationRequired } from 'react-oidc-context';
import { useLocation } from "react-router-dom";
import TriangleIcon from '../../icons/Triangle';
import noPreviewDark from '../../images/no-preview-available-dark.png';
import noPreviewLight from '../../images/no-preview-available-light.png';
import { ClaimContext } from '../../utlis/ClaimContext';
import SnackBar from "../../utlis/SnackBar";
import { useAxios } from "../../utlis/useAxios";
import { useInterval } from "../../utlis/useInterval";
import ViewHandler from './ViewHandler';

const Complete = memo(({ completeRef, reportRef }) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const location = useLocation();

    const { claim, setClaim } = useContext(ClaimContext);

    const { t } = useTranslation();

    const query = new URLSearchParams(location.search);
    const claimUUIDfromURL = query.get('claim');
    const claimUUIDfromStorage = localStorage.getItem("claim");
    const [failed, setFailed] = useState(true);
    const [loading, setLoading] = useState(false);
    const [multiplePhotos, setMultiplePhotos] = useState(false);
    const [failedAttachments, setFailedAttachments] = useState([]);
    const attempted = useRef(false);
    const preview = useRef([]);
    const noPreviewDarkURL = useRef(null);
    const noPreviewLightURL = useRef(null);
    const stage = useRef(null);
    const SnackBarRef = useRef(null);

    const { signoutRedirect } = useAuth();

    const axiosInstance = useAxios();

    //use memoization for claim stages processing
    const claimMemo = useMemo(() => claim, [claim]);

    useEffect(() => {
        document.title = t("completeTitle");
        if (reportRef.current) {
            reportRef.current = {
                "email": "",
                "date": moment().set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0),
                "content": "",
                "attachments": [],
                "contracts": [],
                "loading": false
            };
        };
        if (!claimMemo?.claimUUID && !attempted.current && (claimUUIDfromURL || claimUUIDfromStorage)) {
            fetchClaim();
        }
        if (claimMemo?.claimUUID) {
            if (claimMemo?.attachments?.length > 0) {
                let count = 0;
                for (let i = 0; i < claimMemo?.attachments?.length; i++) {
                    const attachment = claimMemo?.attachments[i];
                    if (attachment?.documentType === "Foto des Schadens") {
                        count += 1;
                        if (count >= 2) {
                            setMultiplePhotos(true);
                        }
                    };
                };
            };
            if (preview.current?.length === 0 && claim?.claimUUID) {
                fetchFallback();
                fetchAllAttachments();
            }
            setFailed(false);
        };
        //eslint-disable-next-line
    }, [claimMemo, preview.current])

    useInterval(() => {
        if (stage.current === "COMPLETED" || stage.current === "FAILED" || (!claimUUIDfromURL && !claimUUIDfromStorage) || failed) {
        } else {
            fetchClaim();
        }
    }, 3000);

    const createObjectUrl = (image, dark) => {
        fetch(image)
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                if (dark) {
                    noPreviewDarkURL.current = url;
                } else {
                    noPreviewLightURL.current = url;
                }
            })
            .catch(err => console.error(err));
    };

    const fetchFallback = async () => {
        await createObjectUrl(noPreviewDark, true);
        await createObjectUrl(noPreviewLight, false);
    };

    const fetchClaim = async () => {
        await axiosInstance.current({
            url: `/claims/${claimUUIDfromURL || claimUUIDfromStorage}`,
            method: "GET"
        }).then((res) => {
            if (!isEqual(claim, res?.data)) {
                setClaim(res?.data ?? {});
            };
            if (res?.data?.currentStage === "COMPLETED" || res?.data?.currentStage === "FAILED") {
                if (stage.current !== null) {
                    if (res.data?.currentStage === "COMPLETED") {
                        SnackBarRef.current.completed(res?.data?.currentStage);
                    } else {
                        SnackBarRef.current.failed(res?.data?.currentStage);
                    }
                };
            } else {
                SnackBarRef.current.stage(res?.data?.currentStage);
            };
            stage.current = res?.data?.currentStage;
        }).catch(err => {
            if (err?.response?.status === 401) {
                signoutRedirect();
            };
            setFailed(true);
        });
        attempted.current = true;
    };

    const fetchAllAttachments = async () => {
        for (let i = 0; i < claim?.attachments?.length; i++) {
            await fetchPreview(claim?.attachments[i]);
        };
    };

    const fetchPreview = async (attachment) => {
        setLoading(true);
        for (let i = 0; i < attachment?.src?.length; i++) {
            const current = attachment?.src[i] ?? undefined;
            await axiosInstance.current({
                url: `/claims/${claimUUIDfromURL || claimUUIDfromStorage}/${current}`,
                method: "GET",
                responseType: "blob"
            }).then((res) => {
                const previewObj = {
                    id: current,
                    data: URL.createObjectURL(res.data)
                }
                preview.current.push(previewObj);
            }).catch(() => setFailedAttachments([...failedAttachments, attachment?.attachmentUUID]));
        };
        setLoading(false);
    };


    if (loading) {
        return (
            <Grid container sx={{ p: 2, height: belowmd ? "calc(100vh - 145px)" : "calc(100vh -75px)" }}>
                <Backdrop
                    sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        );
    };

    if ((!claimUUIDfromURL && !claimUUIDfromStorage) || failed) {
        return (
            <Container sx={{ height: belowmd && !belowsm ? "calc(100vh - 145px)" : "calc(100vh - 75px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography color={theme.palette.text.primary}>Keine Schadenmeldung vorhanden.</Typography>
            </Container>
        )
    };

    const getContent = (attachments) => {
        if (attachments) {
            let matching = [];
            for (let i = 0; i < attachments?.length; i++) {
                if (attachments[i]?.documentType === "Foto des Schadens") {
                    matching.push(attachments[i]);
                }
            };
            return matching;
        };
        return [];
    }

    const DesktopView = () => {

        const [openListClaim, setOpenListClaim] = useState(true);
        const [openListAttachments, setOpenListAttachments] = useState(true);

        const viewHandlerRef = useRef(null);

        const updateCompleteRef = (current) => {
            completeRef.current = current
        };

        useEffect(() => {
            const context = completeRef.current.context;
            switch (context) {
                case "overview":
                    openOverview();
                    break;
                case "completeness":
                    openCompleteness();
                    break;
                case "coverage":
                    openCoverage();
                    break;
                case "invoice":
                    openInvoice();
                    break;
                case "message":
                    openMessage();
                    break;
                case "photo":
                    openPhoto();
                    break;
                default:
                    break;
            };
            //eslint-disable-next-line
        }, []);

        const handleListClaim = () => setOpenListClaim(!openListClaim);
        const handleListAttachments = () => setOpenListAttachments(!openListAttachments);

        const getMatchingPhotos = () => {
            let matching = [];
            let blobOnly = [];
            for (let o = 0; o < claim?.attachments?.length; o++) {
                if (claim?.attachments[o]?.documentType === "Foto des Schadens") {
                    for (let i = 0; i < preview.current?.length; i++) {
                        if (claim.attachments[o]?.src.filter(s => s === preview.current[i]?.id)?.length > 0 && preview.current[i]?.data) {
                            const alreadyAdded = matching.filter(m => claim?.attachments[o]?.src.filter(s => s === m.id)?.length > 0);
                            if (alreadyAdded?.length === 0) {
                                matching.push(preview.current[i]);
                            };
                        };
                    };
                };
            };
            if (matching?.length === 0) {
                const fallback = {
                    id: "fallback-preview",
                    data: theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current
                };
                for (let i = 0; i < claim?.attachments?.length; i++) {
                    if (claim?.attachments[i]?.documentType === "Foto des Schadens") {
                        matching.push(fallback);
                    };
                };
            }
            for (let i = 0; i < matching?.length; i++) {
                blobOnly.push(matching[i].data);
            };
            return blobOnly;
        };

        const openOverview = (input) => viewHandlerRef.current.openOverview(input);
        const openCompleteness = (input) => viewHandlerRef.current.openCompleteness(input);
        const openCoverage = (input) => viewHandlerRef.current.openCoverage(input);
        const openInvoice = (input) => viewHandlerRef.current.openInvoice(input);
        const openMessage = (input) => viewHandlerRef.current.handleMessage(input);
        const openPhoto = (input) => {
            let sourceArray = [];
            if (claim?.attachments?.length > 0) {
                for (let i = 0; i < claim?.attachments?.length; i++) {
                    if (claim?.attachments[i]?.documentType === "Foto des Schadens") {
                        const sourcePath = `/claims/${claim?.claimUUID}/upload/${claim?.attachments[i]?.attachmentUUID}`;
                        const name = claim?.attachments[i]?.filename;
                        const type = claim?.attachments[i]?.contentType;
                        const sourceObj = {
                            "path": sourcePath,
                            "filename": name,
                            "contentType": type
                        };
                        sourceArray.push(sourceObj);
                    };
                };
            };
            viewHandlerRef.current.handlePhoto(getContent(claim?.attachments), getMatchingPhotos(), sourceArray, input);
        };

        const AttachementListItem = ({ displayName, attachment }) => {

            useEffect(() => {
                const context = completeRef.current.context;
                if (context === attachment.attachmentUUID) {
                    openAttachment();
                };
                //eslint-disable-next-line
            }, []);

            const getMatching = () => {
                let matching = [];
                for (let i = 0; i < preview.current?.length; i++) {
                    if ((preview.current[i]?.id === attachment?.attachmentUUID || attachment?.src?.filter(s => s === preview.current[i]?.id)?.length > 0) && preview.current[i]?.data) {
                        matching.push(preview.current[i].data);
                    };
                };
                if (matching.length === 0) {
                    matching.push(theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current);
                }
                return matching;
            };

            const openAttachment = (input) => {
                const type = attachment?.attachmentType;
                let sourceArray = [];
                const sourcePath = `/claims/${claim?.claimUUID}/upload/${attachment?.attachmentUUID}`;
                const sourceObj = {
                    "path": sourcePath,
                    "filename": attachment?.filename,
                    "contentType": attachment?.contentType
                };
                sourceArray.push(sourceObj);
                switch (type) {
                    case "Fnol":
                        viewHandlerRef.current.handleFnol(attachment, getMatching(), sourceArray, input);
                        break;
                    case "PoliceReport":
                        viewHandlerRef.current.handlePoliceReport(attachment, getMatching(), sourceArray, input);
                        break;
                    default:
                        viewHandlerRef.current.handleAttachment(attachment, getMatching(), sourceArray, input);
                        break;
                };
            };

            if (attachment?.documentType === "Foto des Schadens") {
                return;
            };

            return (
                <ListItemButton onClick={() => { openAttachment() }} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                    <ListItemText primary={<Typography noWrap color="inherit">{displayName}</Typography>} />
                </ListItemButton>
            )
        };

        return (
            <Fragment>
                <Grid item xs={2} sx={{ height: belowmd && !belowsm ? "calc(100vh - 218px)" : "calc(100vh - 160px)", mt: 2, pb: 1 }}>
                    <Paper
                        elevation={2}
                        sx={{
                            color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                            height: "100%",
                            background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                            borderRadius: "14.362px",
                            boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none",
                        }}
                    >
                        <List disablePadding sx={{ height: "100%", overflow: "auto" }}>
                            <ListItemButton onClick={handleListClaim} sx={{ pb: 0, pt: 1, borderTopLeftRadius: "14.362px", borderTopRightRadius: "14.362px", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                                <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color="inherit">Meldung</Typography>} />
                                <Box sx={{ transition: 'transform 250ms ease-in-out', transform: openListClaim ? 'rotate(0deg)' : 'rotate(180deg)' }}>
                                    <TriangleIcon sx={{ color: "inherit", ml: 1, mt: .5 }} />
                                </Box>
                            </ListItemButton>
                            <Collapse in={openListClaim} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton onClick={openOverview} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">Übersicht</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openCompleteness} disabled={claimMemo?.completeness === null} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">Vollständigkeit</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openCoverage} disabled={claimMemo?.coverage === null} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">Deckung</Typography>} />
                                    </ListItemButton>
                                    <ListItemButton onClick={openInvoice} disabled={claimMemo?.accountingDocuments === null} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">{claimMemo?.accountingDocuments?.length > 1 ? "Rechnungen" : "Rechnung"}</Typography>} />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                            <ListItemButton onClick={handleListAttachments} sx={{ py: 0, borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                                <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color="inherit">{claimMemo?.attachments?.length > 0 ? "Anhänge" : "Anhang"}</Typography>} />
                                <Box sx={{ transition: 'transform 250ms ease-in-out', transform: openListAttachments ? 'rotate(0deg)' : 'rotate(180deg)' }}>
                                    <TriangleIcon sx={{ color: "inherit", ml: 1, mt: .5 }} />
                                </Box>
                            </ListItemButton>
                            <Collapse in={openListAttachments} timeout="auto" unmountOnExit>
                                <ListItemButton onClick={openMessage} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                    <ListItemText primary={<Typography noWrap color="inherit">Meldung</Typography>} />
                                </ListItemButton>
                                <List component="div" disablePadding sx={{ height: "100%" }}>
                                    <ListItemButton onClick={openPhoto} sx={{ py: .25, pl: 4, borderRadius: "4px", display: getMatchingPhotos().length > 0 ? "flex" : "none" }}>
                                        <ListItemText primary={<Typography noWrap color="inherit">{getMatchingPhotos()?.length > 1 ? "Schadenfotos" : "Schadenfoto"}</Typography>} />
                                    </ListItemButton>
                                    {claimMemo?.attachments?.map((a, i) => { return <AttachementListItem key={`attachment-${i}`} displayName={a?.documentType ?? a?.filename ?? `Anhang ${i + 1}`} attachment={a} /> })}
                                </List>
                            </Collapse>
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={10} sx={{ height: "100%", pl: 2, pt: 2 }}>
                    <ViewHandler completeRef={completeRef} updateCompleteRef={updateCompleteRef} ref={viewHandlerRef} />
                </Grid>
            </Fragment>
        );
    };

    const MobileView = () => {

        const [openListClaim, setOpenListClaim] = useState(false);
        const [openListAttachments, setOpenListAttachments] = useState(false);

        const viewHandlerRef = useRef(null);

        const updateCompleteRef = (current) => completeRef.current = current;

        useEffect(() => {
            const context = completeRef.current.context;
            switch (context) {
                case "overview":
                    openOverview();
                    break;
                case "completeness":
                    openCompleteness();
                    break;
                case "coverage":
                    openCoverage();
                    break;
                case "invoice":
                    openInvoice();
                    break;
                case "message":
                    openMessage();
                    break;
                case "photo":
                    openPhoto();
                    break;
                default:
                    break;
            };
            //eslint-disable-next-line
        }, []);

        const handleListClaim = () => setOpenListClaim(!openListClaim);
        const handleListAttachments = () => setOpenListAttachments(!openListAttachments);

        const getMatchingPhotos = () => {
            let matching = [];
            let blobOnly = [];
            for (let o = 0; o < claim?.attachments?.length; o++) {
                if (claim?.attachments[o]?.documentType === "Foto des Schadens") {
                    for (let i = 0; i < preview.current?.length; i++) {
                        if (claim.attachments[o]?.src.filter(s => s === preview.current[i]?.id)?.length > 0 && preview.current[i]?.data) {
                            const alreadyAdded = matching.filter(m => claim?.attachments[o]?.src.filter(s => s === m.id)?.length > 0);
                            if (alreadyAdded?.length === 0) {
                                matching.push(preview.current[i]);
                            };
                        };
                    };
                };
            };
            if (matching?.length === 0) {
                const fallback = {
                    id: "fallback-preview",
                    data: theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current
                };
                for (let i = 0; i < claim?.attachments?.length; i++) {
                    if (claim?.attachments[i]?.documentType === "Foto des Schadens") {
                        matching.push(fallback);
                    };
                };
            }
            for (let i = 0; i < matching?.length; i++) {
                blobOnly.push(matching[i].data);
            };
            return blobOnly;
        };

        const openOverview = (input) => {
            viewHandlerRef.current.openOverview(input);
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };

        const openCompleteness = (input) => {
            viewHandlerRef.current.openCompleteness(input);
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };

        const openCoverage = (input) => {
            viewHandlerRef.current.openCoverage(input);
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };

        const openInvoice = (input) => {
            viewHandlerRef.current.openInvoice(input);
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };

        const openMessage = (input) => {
            viewHandlerRef.current.handleMessage(input);
            setOpenListClaim(false);
            setOpenListAttachments(false);
        };

        const openPhoto = (input) => {
            let sourceArray = [];
            if (claim?.attachments?.length > 0) {
                for (let i = 0; i < claim?.attachments?.length; i++) {
                    if (claim?.attachments[i]?.documentType === "Foto des Schadens") {
                        const sourcePath = `/claims/${claim?.claimUUID}/upload/${claim?.attachments[i]?.attachmentUUID}`;
                        const name = claim?.attachments[i]?.filename;
                        const type = claim?.attachments[i]?.contentType;
                        const sourceObj = {
                            "path": sourcePath,
                            "filename": name,
                            "contentType": type
                        };
                        sourceArray.push(sourceObj);
                    };
                };
            };
            viewHandlerRef.current.handlePhoto(getContent(claim?.attachments), getMatchingPhotos(), sourceArray, input);
            setOpenListAttachments(false);
        };

        const AttachementListItem = ({ displayName, attachment }) => {

            useEffect(() => {
                const context = completeRef.current.context;
                if (context === attachment.attachmentUUID && !openListClaim && !openListAttachments) {
                    openAttachment();
                };
                //eslint-disable-next-line
            }, []);

            const getMatching = () => {
                let matching = [];
                for (let i = 0; i < preview.current?.length; i++) {
                    if ((preview.current[i]?.id === attachment?.attachmentUUID || (attachment.src && attachment?.src?.filter(s => s === preview.current[i]?.id)?.length > 0)) && preview.current[i]?.data) {
                        matching.push(preview.current[i].data);
                    };
                };
                if (matching.length === 0) {
                    matching.push(theme.palette.mode === "dark" ? noPreviewDarkURL.current : noPreviewLightURL.current);
                }
                return matching;
            };

            const openAttachment = (input) => {
                setOpenListClaim(false);
                setOpenListAttachments(false);
                const type = attachment?.attachmentType;
                let sourceArray = [];
                const sourcePath = `/claims/${claim?.claimUUID}/upload/${attachment?.attachmentUUID}`;
                const sourceObj = {
                    "path": sourcePath,
                    "filename": attachment?.filename,
                    "contentType": attachment?.contentType
                };
                sourceArray.push(sourceObj);
                switch (type) {
                    case "Fnol":
                        viewHandlerRef.current.handleFnol(attachment, getMatching(), sourceArray, input);
                        break;
                    case "PoliceReport":
                        viewHandlerRef.current.handlePoliceReport(attachment, getMatching(), sourceArray, input);
                        break;
                    default:
                        viewHandlerRef.current.handleAttachment(attachment, getMatching(), sourceArray, input);
                        break;
                };
            };

            if (attachment?.documentType === "Foto des Schadens") {
                return;
            }

            return (
                <ListItemButton onClick={(event) => { openAttachment(event) }} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                    <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{displayName}</Typography>} />
                </ListItemButton>
            )
        };

        return (
            <Fragment>
                <Paper
                    elevation={2}
                    sx={{
                        mt: 2,
                        width: "100%",
                        color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary",
                        background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                        borderRadius: "14.362px",
                        boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none",
                    }}
                >
                    <List disablePadding>
                        <ListItemButton onClick={handleListClaim} sx={{ p: 0, px: 1, borderTopLeftRadius: "14.362px", borderTopRightRadius: "14.362px", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                            <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>Meldung</Typography>} />
                            <IconButton sx={{ transform: openListClaim ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 250ms ease-in-out' }}>
                                <TriangleIcon sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", ml: 1, mt: .5 }} />
                            </IconButton>
                        </ListItemButton>
                        <Collapse in={openListClaim} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton onClick={openOverview} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                                    <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>Übersicht</Typography>} />
                                </ListItemButton>
                                <ListItemButton onClick={openCompleteness} disabled={claimMemo?.completeness === null} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                                    <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>Vollständigkeit</Typography>} />
                                </ListItemButton>
                                <ListItemButton onClick={openCoverage} disabled={claimMemo?.coverage === null} sx={{ py: .25, pl: 4, borderRadius: "6px" }}>
                                    <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>Deckung</Typography>} />
                                </ListItemButton>
                                <ListItemButton onClick={openInvoice} disabled={claimMemo?.accountingDocuments === null} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                    <ListItemText primary={<Typography noWrap color="inherit">{claimMemo?.accountingDocuments?.length > 1 ? "Rechnungen" : "Rechnung"}</Typography>} />
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleListAttachments} sx={{ p: 0, px: 1, borderBottom: openListAttachments ? 1 : 0, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                            <ListItemText primary={<Typography variant="h6" noWrap fontWeight={600} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>Anhänge</Typography>} />
                            <IconButton sx={{ transform: openListAttachments ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 250ms ease-in-out' }}>
                                <TriangleIcon sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", ml: 1, mt: .5 }} />
                            </IconButton>
                        </ListItemButton>
                        <Collapse in={openListAttachments} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding sx={{ height: "100%" }}>
                                <ListItemButton onClick={openMessage} sx={{ py: .25, pl: 4, borderRadius: "4px" }}>
                                    <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>Meldung</Typography>} />
                                </ListItemButton>
                                <ListItemButton onClick={openPhoto} sx={{ py: .25, pl: 4, borderRadius: "6px", display: getMatchingPhotos().length > 0 ? "flex" : "none" }}>
                                    <ListItemText primary={<Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{multiplePhotos ? "Schadenfotos" : "Schadenfoto"}</Typography>} />
                                </ListItemButton>
                                {claimMemo?.attachments?.map((a, i) => { return <AttachementListItem key={`attachment-${i}`} displayName={a?.documentType ?? a?.filename ?? `Anhang ${i + 1}`} attachment={a} /> })}
                            </List>
                        </Collapse>
                    </List>
                </Paper>
                <ViewHandler completeRef={completeRef} updateCompleteRef={updateCompleteRef} ref={viewHandlerRef} />
            </Fragment>
        );
    };

    return (
        <Fragment>
            <Grid container sx={{ p: 2, pt: 0, height: belowsm ? "calc(100vh - 277px)" : belowmd ? "calc(100vh - 178px)" : "calc(100vh - 124px)" }}>
                {belowsm ? <MobileView /> : <DesktopView />}
            </Grid>
            <SnackBar ref={SnackBarRef} />
        </Fragment>
    );

}, (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));
// Complete.whyDidYouRender = true
export default withAuthenticationRequired(Complete, {
    OnRedirecting: () => {
        const redirect = `${window.location?.pathname ?? ""}${window.location?.search ?? ""}`;
        localStorage.setItem("redirect", redirect);
    }
});