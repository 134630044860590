import { Collapse, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TriangleIcon from '../../../icons/Triangle';
import WidgetTextField from "../WidgetTextField";

const InvoiceWidget = ({ obj }) => {

    const { t } = useTranslation();

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const [collapse, setCollapse] = useState(true);

    const handleCollapse = () => setCollapse(!collapse);

    const TextField = ({ dataKey, value }) => {

        return <WidgetTextField
            color="widget"
            label={t(value?.internal ?? "missingInternal")}
            value={value?.api ?? ""}
            variant="standard"
            fullWidth
            focused
            multiline={belowsm ? true : false}
            maxRows={3}
            InputLabelProps={{
                sx: { px: 1 }
            }}
            InputProps={{
                readOnly: true,
                sx: {
                    px: 1,
                    color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"
                }
            }}
            sx={{ mt: 1 }}
        />
    };

    const HalfSizedTextField = ({ dataKey, value }) => {

        return (
            <Grid item xs={6}>
                <WidgetTextField
                    color="widget"
                    label={t(value?.internal ?? "missingInternal")}
                    value={value?.api ?? ""}
                    variant="standard"
                    fullWidth
                    focused
                    multiline={belowsm ? true : false}
                    maxRows={3}
                    InputLabelProps={{
                        sx: { px: 1 }
                    }}
                    InputProps={{
                        readOnly: true,
                        sx: {
                            px: 1,
                            color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"
                        }
                    }}
                    sx={{ mt: 1 }}
                />
            </Grid>
        );
    };

    return (
        <Grid item xs={12}>
            <Paper
                elevation={2}
                sx={{
                    pt: 1,
                    pb: 1,
                    background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                    borderRadius: "14.362px",
                    boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none",
                    overflow: "auto"
                }}
            >
                <Grid container
                    sx={{
                        px: 1,
                        pb: collapse ? 1 : 0,
                        borderBottom: collapse ? 1 : 0,
                        borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                    }}
                >
                    <Grid item xs={11}>
                        <Typography
                            noWrap
                            sx={{
                                color: collapse ? theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary" : theme.palette.widget.main,
                                fontSize: belowsm ? "20px" : "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                mt: belowsm ? .75 : .5,
                                pr: 2
                            }}
                        >
                            {obj?.title ?? ""}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end", pr: belowsm ? 0 : 2 }}>
                        <IconButton
                            onClick={handleCollapse}
                            sx={{
                                transform: collapse ? 'rotate(0deg)' : 'rotate(180deg)',
                                transition: 'transform 250ms ease-in-out',
                            }}>
                            <TriangleIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Collapse
                    in={collapse}
                    mountOnEnter
                >
                    <Grid container>
                        {!belowsm ? Object.entries(obj?.data ?? {}).map(([key, value], i) => { return <HalfSizedTextField key={`TextField-${i}`} dataKey={key} value={value} /> })
                            : Object.entries(obj?.data ?? {}).map(([key, value], i) => { return <TextField key={`TextField-${i}`} dataKey={key} value={value} /> })}
                    </Grid>
                    <Grid container sx={{ mt: 2, display: obj?.positions ? "flex" : "none" }}>
                        <Typography
                            noWrap
                            sx={{
                                color: theme.palette.widget.main,
                                fontSize: belowsm ? "16px" : "16px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                ml: 1
                            }}
                        >
                            Positionen
                        </Typography>
                        <TableContainer sx={{ maxHeight: 440, overflowX: "auto", width: belowsm ? "91vw" : "100%" }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{ fontWeight: 600, color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>Pos</TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 600, color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>Art</TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 600, color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>Menge</TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 600, color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>Einheit</TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 600, color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>Text</TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 600, color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>Summe</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {obj?.positions?.map((p, i) => {
                                        return (<TableRow key={`pos-${i}`} sx={{
                                            "&:last-child td, &:last-child th": {
                                                border: 0,
                                            }
                                        }}>
                                            <TableCell align="left" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>{p?.pos ?? ""}</TableCell>
                                            <TableCell align="center" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>{p?.type ?? ""}</TableCell>
                                            <TableCell align="center" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>{p?.amount ?? ""}</TableCell>
                                            <TableCell align="center" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>{p?.unit ?? ""}</TableCell>
                                            <TableCell align="left" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>{p?.text ?? ""}</TableCell>
                                            <TableCell align="center" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>{p?.sum ?? ""}</TableCell>
                                        </TableRow>)
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {obj?.positions?.length === 0 ?
                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ my: .5, width: "100%", textAlign: "center", fontSize: "14px" }}>{t("noEntriesAvailable")}</Typography>
                            : null}
                    </Grid>
                </Collapse>
            </Paper>
        </Grid>
    )
};

export default InvoiceWidget;