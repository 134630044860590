import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Avatar, Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

const Attachment = memo(({ data, attachmentPreview }) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));
    const below818 = useMediaQuery('(max-width:818px)')

    const { t } = useTranslation();

    const [attachment, setAttachment] = useState();
    const [preview, setPreview] = useState([]);

    const [isOverflowing1, setIsOverflowing1] = useState(false);
    const [isOverflowing2, setIsOverflowing2] = useState(false);

    const contentRef1 = useRef(null);
    const contentRef2 = useRef(null);

    useEffect(() => {
        if (data) {
            setAttachment(data);
        }
        if (attachmentPreview) {
            setPreview(attachmentPreview);
        }
        const handleResize = () => {
            checkOverflow1();
            checkOverflow2();
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
        //eslint-disable-next-line
    }, [data, attachment])

    const checkOverflow1 = () => {
        if (contentRef1.current) {
            const hasOverflow = contentRef1.current.scrollHeight > contentRef1.current.clientHeight;
            setIsOverflowing1(hasOverflow);
        }
    };
    const checkOverflow2 = () => {
        if (contentRef2.current) {
            const hasOverflow = contentRef2.current.scrollHeight > contentRef2.current.clientHeight;
            setIsOverflowing2(hasOverflow);
        }
    };

    const checkInput = (input, notApplicable) => {
        if (input && input?.length > 0) {
            return input;
        };
        if (notApplicable) {
            return "Nicht anwendbar";
        };
        return "Nicht gefunden";
    };

    const Preview = () => {

        return (
            <Grid container sx={{ display: preview?.length > 0 ? "flex" : "none", justifyContent: below818 ? "center" : "flex-start" }}>
                {preview?.map((p, i) => {
                    return <Grid item key={i} sx={{ maxWidth: belowsm ? "100%" : "300px", m: .5, border: belowsm ? 0 : 1, borderTop: belowsm && i === 0 ? 0 : 1, borderRadius: belowsm ? "0px" : "4px", borderColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                        <img alt={`preview-${i}`} src={p} style={{ borderRadius: belowsm ? "0px" : "4px", width: "100%" }} />
                    </Grid>
                })}
            </Grid>
        );
    };

    return (
        <Grid container direction={belowsm ? "row" : "column"} sx={{ height: belowsm ? "calc(100vh - 237px)" : "auto", overflow: "auto" }}>
            <Grid item xs={12} sx={{ pb: .5 }}>
                <Paper
                    elevation={2}
                    sx={{
                        pb: 1,
                        background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                        borderRadius: "14.362px",
                        boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                    }}
                >
                    <Typography
                        noWrap
                        sx={{
                            color: theme.palette.widget.main,
                            fontSize: belowsm ? "20px" : "24px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            mt: belowsm ? .75 : .5,
                            p: 1,
                            borderBottom: 1,
                            borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                        }}
                    >
                        {attachment?.filename}
                    </Typography>
                    <Box sx={{ p: .5 }}>
                        <Preview />
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} sx={{ pb: 2 }}>
                <Paper
                    elevation={2}
                    sx={{
                        pb: 1,
                        background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                        borderRadius: "14.362px",
                        boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                    }}
                >
                    <Typography
                        noWrap
                        sx={{
                            color: theme.palette.widget.main,
                            fontSize: belowsm ? "20px" : "24px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            mt: belowsm ? .75 : .5,
                            p: 1,
                            borderBottom: 1,
                            borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                        }}
                    >
                        {t("documentContent")}
                    </Typography>
                    <Box sx={{ p: 1 }}>
                        <Grid container>
                            <Grid item xs={12} sm={6} sx={{ pr: 2, display: attachment?.attachmentType === "AccountingDocument" ? "block" : "none" }}>
                                <Grid container columnGap={2} sx={{ pb: isOverflowing1 ? .5 : 0, display: belowsm ? "none" : "flex" }}>
                                    <Grid item>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ width: "113px" }}>Aussteller*in:</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Box ref={contentRef1} sx={{ maxHeight: belowmd ? belowsm ? null : "84px" : "60px", overflow: belowsm ? "visible" : "auto" }}>
                                            <Typography noWrap={attachment?.issuer && attachment?.issuer?.length > 0 ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.issuer)}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container columnGap={2} sx={{ pt: isOverflowing2 ? .5 : 0, display: belowsm ? "none" : "flex" }}>
                                    <Grid item>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ width: "113px" }}>Empfänger*in:</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Box ref={contentRef2} sx={{ maxHeight: belowmd ? belowsm ? null : "84px" : "60px", overflow: belowsm ? "visible" : "auto" }}>
                                            <Typography noWrap={attachment?.recipient && attachment?.recipient?.length > 0 ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.recipient)}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container columnGap={belowsm ? 0 : 2}>
                                    <Grid item>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none", flexGrow: 1 }}>Aussteller*in:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.issuer)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none", flexGrow: 1 }}>Empfänger*in:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.recipient)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0, width: "113px" }}>Netto:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.net)}</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.net)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ display: attachment?.attachmentType === "AccountingDocument" ? "block" : "none" }}>
                                <Grid container columnGap={belowsm ? 0 : 2}>
                                    <Grid item xs={belowsm ? false : "auto"} zeroMinWidth>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0 }}>Rechungsnummer:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.invoicenumber)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0 }}>Datum:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.date)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0 }}>Brutto:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.gross)}</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.invoicenumber)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.date)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.gross)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ mt: belowsm ? 0 : 2, display: attachment?.attachmentType === "AccountingDocument" ? "block" : "none" }}>
                                <Grid container columnGap={belowsm ? 0 : 2}>
                                    <Grid item xs={belowsm ? false : "auto"} zeroMinWidth>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0 }}>Angebotsnummer:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.offernumber)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0 }}>Addresse vom Aussteller*in:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.issuerAddress)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0 }}>Addresse vom Empfänger*in:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.recipientAddress)}</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.offernumber)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.issuerAddress)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.recipientAddress)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Grid container>
                                    <Grid item>
                                        <Avatar sx={{ height: 20, width: 20, bgcolor: attachment?.related ? "success.main" : "error.main", mr: 2, mt: .2 }}>
                                            {attachment?.related ? <CheckIcon sx={{ color: "#fff", fontSize: "16px" }} /> : <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} />}
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography noWrap={belowsm ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{attachment?.related ? "Anhang passt zur Schadenbeschreibung" : "Anhang passt nicht zur Schadenbeschreibung"}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ my: 1 }}>
                                <Box sx={{ maxHeight: belowmd ? belowsm ? null : "84px" : "60px", overflow: belowsm ? "visible" : "auto" }}>
                                    <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.description)}</Typography>
                                </Box>
                            </Grid>
                            <Grid container columnGap={belowsm ? 0 : 2} sx={{ mt: 1 }}>
                                <Grid item>
                                    <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>Begründung:</Typography>
                                    <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.relatedReasoning)}</Typography>
                                </Grid>
                                <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                                    <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.relatedReasoning)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} zeroMinWidth sx={{ display: attachment?.attachmentType === "AccountingDocument" ? "block" : "none" }}>
                                <Typography variant="h6" noWrap fontWeight={600} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>Positionen:</Typography>
                            </Grid>
                            <Grid item xs={12} zeroMinWidth sx={{ display: attachment?.attachmentType === "AccountingDocument" ? "block" : "none" }}>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>Pos</TableCell>
                                                <TableCell align="left" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>Art</TableCell>
                                                <TableCell align="left" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>Menge</TableCell>
                                                <TableCell align="left" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>Einheit</TableCell>
                                                <TableCell align="left" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>Text</TableCell>
                                                <TableCell align="right" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>Summe</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {attachment?.positions?.map((p, i) => {
                                                return (<TableRow key={`pos-${i}`} sx={{
                                                    "&:last-child td, &:last-child th": {
                                                        border: 0,
                                                    }
                                                }}>
                                                    <TableCell align="left" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>{p?.pos ?? ""}</TableCell>
                                                    <TableCell align="left" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>{p?.type ?? ""}</TableCell>
                                                    <TableCell align="left" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>{p?.amount ?? ""}</TableCell>
                                                    <TableCell align="left" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>{p?.unit ?? ""}</TableCell>
                                                    <TableCell align="left" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>{p?.text ?? ""}</TableCell>
                                                    <TableCell align="right" sx={{ color: theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary", bgcolor: "transparent", borderBottom: 1, borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>{p?.sum ?? ""}</TableCell>
                                                </TableRow>)
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {attachment?.positions?.length === 0 ?
                                    <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ my: .5, width: "100%", textAlign: "center" }}>Keine Angaben vorhanden</Typography>
                                    : null}
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} sx={{ display: attachment?.hasPayment ? "block" : "none" }}>
                <Box sx={{ height: belowsm ? "auto" : "100%", pb: 2 }}>
                    <Paper
                        elevation={2}
                        sx={{
                            pb: 1,
                            background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                            borderRadius: "14.362px",
                            boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                        }}
                    >
                        <Typography
                            noWrap
                            sx={{
                                color: theme.palette.widget.main,
                                fontSize: belowsm ? "20px" : "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                mt: belowsm ? .75 : .5,
                                p: 1,
                                borderBottom: 1,
                                borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                            }}
                        >
                            {t("payment")}
                        </Typography>
                        <Box sx={{ p: 1 }}>
                            <Grid container>
                                <Grid item xs={12} zeroMinWidth>
                                    <Grid container columnGap={belowmd ? 0 : 2}>
                                        <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>IBAN:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.paymentIban)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>Zahlungsempfänger*in:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.paymentRecipient, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>Betrag laut Meldung:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.paymentAmount, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>Zahlungsgrund / Referenz:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.paymentInfo, true)}</Typography>
                                        </Grid>
                                        <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.paymentIban, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.paymentRecipient, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.paymentAmount, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.paymentInfo, true)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ display: attachment?.hasCar ? "block" : "none" }}>
                <Box sx={{ height: belowsm ? "auto" : "100%", pb: .5 }}>
                    <Paper
                        elevation={2}
                        sx={{
                            pb: 1,
                            background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                            borderRadius: "14.362px",
                            boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                        }}
                    >
                        <Typography
                            noWrap
                            sx={{
                                color: theme.palette.widget.main,
                                fontSize: belowsm ? "20px" : "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                mt: belowsm ? .75 : .5,
                                p: 1,
                                borderBottom: 1,
                                borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                            }}
                        >
                            {t("car")}
                        </Typography>
                        <Box sx={{ p: 1 }}>
                            <Grid container>
                                <Grid item xs={12} sm={6} zeroMinWidth sx={{ pr: belowmd ? 0 : 1 }}>
                                    <Grid container columnGap={belowmd ? 0 : 2}>
                                        <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>{`${t("carLicensePlate")}:`}</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carLicensePlate, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>{`${t("carVin")}:`}</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carVin, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>{`${t("carYear")}:`}</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carYear, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "206px" }}>{`${t("carMileage")}:`}</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.chatCar?.carMileage, true)}</Typography>
                                        </Grid>
                                        <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carLicensePlate, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carVin, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carYear, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carMileage, true)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} zeroMinWidth sx={{ pr: belowmd ? 0 : 1 }}>
                                    <Grid container columnGap={belowmd ? 0 : 2}>
                                        <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>{`${t("carMake")}:`}</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carMake, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>{`${t("carModel")}:`}</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carModel, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>{`${t("carColor")}:`}</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carColor, true)}</Typography>
                                        </Grid>
                                        <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carMake, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carModel, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carColor, true)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} zeroMinWidth sx={{ mt: belowmd ? 0 : 1 }}>
                                    <Grid container columnGap={belowmd ? 0 : 2}>
                                        <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>{`${t("carDamage")}:`}</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carDamage, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>{`${t("carDamageLocation")}:`}</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carDamageLocation, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>{`${t("carLocation")}:`}</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carLocation, true)}</Typography>
                                        </Grid>
                                        <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carDamage, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carDamageLocation, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carLocation, true)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    );
});

export default Attachment;