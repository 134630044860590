import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";

const HelpDialog = forwardRef((props, ref) => {

    const theme = useTheme();

    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        open() {
            setOpen(true);
        }
    }));

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Dialog
            fullWidth
            maxWidth={'lg'}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: { borderRadius: "14.362px" }
            }}
        >
            <DialogTitle sx={{ textAlign: "center" }}></DialogTitle>
            <DialogContent>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ lineHeight: "normal", textAlign: "center" }}>In Emails wird oft ein relatives Datum  wie "gestern" oder "letzte Woche" verwendet. Das Sendedatum wird herangezogen, um solche Angaben in ein absolutes Datum umzuwandeln.</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disableElevation onClick={handleClose} sx={{ textTransform: "none", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>Schließen</Button>
            </DialogActions>
        </Dialog>
    );

});

export default HelpDialog;