import { createContext, useMemo, useState } from "react";

export const ClaimContext = createContext({
    claim: {},
    setClaim: () => { }
});

export default function ClaimProvider({ children }) {

    const [claim, setClaim] = useState({});

    const value = useMemo(
        () => ({ claim, setClaim }),
        [claim]
    );

    return <ClaimContext.Provider value={value}>{children}</ClaimContext.Provider>
};