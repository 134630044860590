import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";

const UserDialog = forwardRef((props, ref) => {

    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState();


    useImperativeHandle(ref, () => ({
        open(data) {
            setOpen(true);
            setEmail(data);
        }
    }));

    const handleClose = () => {
        setOpen(false);
    }


    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: { borderRadius: "14.362px" }
            }}
        >
            <DialogTitle fontWeight={600} sx={{ textAlign: "center" }}>Profil</DialogTitle>
            <DialogContent sx={{ justifyContent: "center" }}>
                <Typography variant="body1" color={theme.palette.text.primary} sx={{ mx: 2, lineHeight: "normal", textAlign: "center" }}>{`Angemeldet mit ${email}`}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disableElevation onClick={handleClose} sx={{ textTransform: "none", bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main", "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" } }}>Schließen</Button>
            </DialogActions>
        </Dialog>
    );

});

export default UserDialog;