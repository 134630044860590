import { Backdrop, Box, CircularProgress, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ClaimContext } from "../../../utlis/ClaimContext";
import { useConvertDto } from '../../../utlis/useConvertDto';
import CoverageConditionWidget from './CoverageConditionWidget';
import Widget from "../Widget";
import CoverageLimitWidget from "./CoverageLimitWidget";

const Coverage = () => {
    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));

    const { claim } = useContext(ClaimContext);
    const [loading, setLoading] = useState(true);

    const { getCoverageSummary, getCoverageCondition, getCoverageLimit } = useConvertDto();

    useEffect(() => {
        if (claim?.claimUUID) {
            setLoading(false);
        };
        //eslint-disable-next-line
    }, [claim]);

    if (loading) {
        return (
            <Backdrop
                sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    return (
        <Grid container direction={belowsm ? "row" : "column"} sx={{ mb: .5, width: belowsm ? "91vw" : "auto" }}>
            <Grid item xs={12}>
                <Grid container>
                    <Widget obj={getCoverageSummary()} noMaxRows={true} fullSize={true} />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                {claim?.coverage?.conditions?.map((c, i) => { return (<Box key={`condition-${i}`} sx={{ mt: 2 }}><CoverageConditionWidget obj={getCoverageCondition(i)} noMaxRows={true} /></Box>) })}
            </Grid>
            <Grid item xs={12}>
                {claim?.coverage?.limits?.map((l, i) => { return (<Box key={`limit-${i}`} sx={{ mt: 2 }}><CoverageLimitWidget obj={getCoverageLimit(i)} noMaxRows={true} /></Box>) })}
            </Grid>
        </Grid>
    );
};

export default Coverage;