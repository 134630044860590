import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Backdrop, Box, Button, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isEqual } from 'lodash';
import { Fragment, forwardRef, memo, useContext, useImperativeHandle, useRef, useState } from "react";
import { ClaimContext } from '../../utlis/ClaimContext';
import { useAxios } from "../../utlis/useAxios";
import { useInterval } from '../../utlis/useInterval';
import Attachment from "./Attachment/Attachment";
import FnolAttachment from './Attachment/FnolAttachment';
import Message from "./Attachment/Message";
import Photo from "./Attachment/Photo";
import PoliceReportAttachment from './Attachment/PoliceReportAttachment';
import Completeness from './Completeness';
import Coverage from './Coverage/Coverage';
import Invoice from './Invoice/Invoice';
import Overview from "./Overview/Overview";

const ViewHandler = memo(forwardRef(({ completeRef, updateCompleteRef }, ref) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));

    const [photo, setPhoto] = useState();
    const [attachment, setAttachment] = useState();
    const [photoPreview, setPhotoPreview] = useState([]);
    const [attachmentPreview, setAttachmentPreview] = useState([]);
    const [source, setSource] = useState([]);
    const [displayOverview, setDisplayOverview] = useState(false);
    const [displayMessage, setDisplayMessage] = useState(false);
    const [displayPhoto, setDisplayPhoto] = useState(false);
    const [displayAttachment, setDisplayAttachment] = useState(false);
    const [displayFnol, setDisplayFnol] = useState(false);
    const [displayPoliceReport, setDisplayPoliceReport] = useState(false);
    const [displaySource, setDisplaySource] = useState(false);
    const [displayCoverage, setDisplayCoverage] = useState(false);
    const [displayCompleteness, setDisplayCompleteness] = useState(false);
    const [displayInvoice, setDisplayInvoice] = useState(false);
    const [loading, setLoading] = useState(false);

    const { claim } = useContext(ClaimContext);

    const axiosInstance = useAxios();

    //eslint-disable-next-line
    const fetchPdf = async () => {
        setLoading(true);

        await axiosInstance.current({
            url: `/pdf/${claim?.claimUUID}`,
            method: "GET",
            responseType: "blob"
        }).then((res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `pdf-report-${claim?.claimUUID}.pdf`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        }).catch(err => console.error(err));
        setLoading(false);
    };

    const fetchSource = async (data) => {
        setLoading(true);

        await axiosInstance.current({
            url: data?.path || "",
            method: "GET",
            responseType: "blob"
        }).then((res) => {
            const blob = new Blob([res.data], { type: data?.contentType || "text/plain" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${data?.filename || "source-document.txt"}`);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        }).catch(err => console.error(err));
        setLoading(false);
    };

    const downloadAllSource = async () => {
        for (let i = 0; i < source?.length; i++) {
            await fetchSource(source[i]);
        };
    };

    useImperativeHandle(ref, () => ({
        openOverview(input) {
            // console.log("keepscroll:", keepScroll?.type);
            updateCompleteRef({
                "context": "overview",
                "scrollTop": input?.type === "click" ? 0 : completeRef.current?.scrollTop ?? 0
            });
            setDisplayOverview(true);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplaySource(false);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayInvoice(false);
        },
        openCompleteness(input) {
            updateCompleteRef({
                "context": "completeness",
                "scrollTop": input?.type === "click" ? 0 : completeRef.current?.scrollTop ?? 0
            });
            setDisplayCompleteness(true);
            setDisplayCoverage(false);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplaySource(false);
            setDisplayInvoice(false);
        },
        openCoverage(input) {
            updateCompleteRef({
                "context": "coverage",
                "scrollTop": input?.type === "click" ? 0 : completeRef.current?.scrollTop ?? 0
            });
            setDisplayCoverage(true);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplaySource(false);
            setDisplayCompleteness(false);
            setDisplayInvoice(false);
        },
        openInvoice(input) {
            updateCompleteRef({
                "context": "invoice",
                "scrollTop": input?.type === "click" ? 0 : completeRef.current?.scrollTop ?? 0
            });
            setDisplayInvoice(true);
            setDisplayCoverage(false);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplaySource(false);
            setDisplayCompleteness(false);
        },
        handleMessage(input) {
            updateCompleteRef({
                "context": "message",
                "scrollTop": input?.type === "click" ? 0 : completeRef.current?.scrollTop ?? 0
            });
            setDisplayMessage(true);
            setDisplayOverview(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplaySource(false);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayInvoice(false);
        },
        handlePhoto(data, preview, sourceArray, input) {
            updateCompleteRef({
                "context": "photo",
                "scrollTop": input?.type === "click" ? 0 : completeRef.current?.scrollTop ?? 0
            });
            setPhoto(data);
            setPhotoPreview(preview);
            setSource(sourceArray);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(false);
            setDisplayPhoto(true);
            setDisplaySource(true);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayInvoice(false);
        },
        handleAttachment(data, preview, sourceArray, input) {
            updateCompleteRef({
                "context": data?.attachmentUUID ?? null,
                "scrollTop": input?.type === "click" ? 0 : completeRef.current?.scrollTop ?? 0
            });
            setAttachment(data);
            setAttachmentPreview(preview);
            setSource(sourceArray);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(false);
            setDisplayAttachment(true);
            setDisplaySource(true);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayInvoice(false);
        },
        handleFnol(data, preview, sourceArray, input) {
            updateCompleteRef({
                "context": data?.attachmentUUID ?? null,
                "scrollTop": input?.type === "click" ? 0 : completeRef.current?.scrollTop ?? 0
            });
            setAttachment(data);
            setAttachmentPreview(preview);
            setSource(sourceArray)
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayAttachment(false);
            setDisplayPoliceReport(false);
            setDisplayFnol(true);
            setDisplaySource(true);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayInvoice(false);
        },
        handlePoliceReport(data, preview, sourceArray, input) {
            updateCompleteRef({
                "context": data?.attachmentUUID ?? null,
                "scrollTop": input?.type === "click" ? 0 : completeRef.current?.scrollTop ?? 0
            });
            setAttachment(data);
            setAttachmentPreview(preview);
            setSource(sourceArray);
            setDisplayOverview(false);
            setDisplayMessage(false);
            setDisplayPhoto(false);
            setDisplayAttachment(false);
            setDisplayFnol(false);
            setDisplayPoliceReport(true);
            setDisplaySource(true);
            setDisplayCoverage(false);
            setDisplayCompleteness(false);
            setDisplayInvoice(false);
        }
    }));

    const closeView = () => {
        updateCompleteRef({
            "context": null,
            "scrollTop": null
        });
        setDisplayOverview(false);
        setDisplayMessage(false);
        setDisplayPhoto(false);
        setDisplayAttachment(false);
        setDisplayFnol(false);
        setDisplayPoliceReport(false);
        setDisplaySource(false);
        setDisplayCoverage(false);
        setDisplayCompleteness(false);
        setDisplayInvoice(false);
        if (belowsm) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    const handleMobileStageView = () => {
        if (claim?.currentStage === "COMPLETED" || claim?.currentStage === "FAILED") {
            return false;
        } else {
            return true;
        }
    };

    const WithActionButtons = memo(({ component }) => {

        const boxRef = useRef(null);

        const handleScroll = () => updateCompleteRef({
            ...completeRef.current,
            "scrollTop": boxRef.current.scrollTop
        });

        useInterval(() => {
            const current = boxRef.current?.scrollTop;
            const scrollTop = completeRef.current?.scrollTop;
            if (current !== scrollTop) {
                boxRef.current.scrollTo(0, scrollTop ? scrollTop : 0);
            };
        }, belowsm ? 450 : 150)

        return (
            <Fragment>
                <Box ref={boxRef} onScroll={handleScroll} sx={{ height: belowsm ? displaySource ? handleMobileStageView() ? "calc(100vh - 416px)" : "calc(100vh - 354px)" : handleMobileStageView() ? "calc(100vh - 366px)" : "calc(100vh - 304px)" : belowmd ? "calc(100vh - 223px)" : "calc(100vh - 165px)", overflow: "auto", mt: belowsm ? handleMobileStageView() ? 10 : 2 : 0, borderRadius: "14.362px" }}>
                    {component}
                </Box>
                <Grid container>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                        onClick={fetchPdf}
                        disableElevation
                        variant="contained"
                        size="large"
                        fullWidth={belowsm ? true : false}
                        startIcon={<PictureAsPdfOutlinedIcon />}
                        disabled={claim?.flags?.claim === false || claim?.flags?.personalInjury}
                        sx={{
                            mt: 2,
                            mb: belowsm ? 0 : 2,
                            mr: belowsm ? 0 : 1,
                            textTransform: "none",
                            borderRadius: "8px",
                            bgcolor: theme.palette.widget.main,
                            "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                        }}>PDF Report
                    </Button>
                    <Button
                        onClick={downloadAllSource}
                        disableElevation
                        variant="contained"
                        size="large"
                        fullWidth={belowsm ? true : false}
                        startIcon={<DownloadForOfflineOutlinedIcon />}
                        sx={{
                            display: displaySource ? "inline-flex" : "none",
                            mt: belowsm ? 1 : 2,
                            mb: belowsm ? 0 : 2,
                            mr: belowsm ? 0 : 1,
                            textTransform: "none",
                            borderRadius: "8px",
                            bgcolor: theme.palette.widget.main,
                            "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                        }}>Original Download
                    </Button>
                    <Button
                        onClick={closeView}
                        disableElevation
                        variant="contained"
                        size="large"
                        fullWidth={belowsm ? true : false}
                        startIcon={<ArrowCircleLeftOutlinedIcon />}
                        sx={{
                            mt: belowsm ? 1 : 2,
                            mb: 2,
                            textTransform: "none",
                            borderRadius: "8px",
                            bgcolor: theme.palette.widget.main,
                            "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                        }}>Zurück
                    </Button>
                </Grid>
            </Fragment>
        );
    }, (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));

    if (loading) {
        return (
            <Grid container sx={{ p: 2, height: belowsm ? "calc(100vh - 277px)" : belowmd ? "calc(100vh - 283px)" : "calc(100vh - 224px)" }}>
                <Backdrop
                    sx={{ color: theme.palette.mode === "dark" ? theme.palette.primary.light : theme.palette.primary.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        );
    };

    if (displayOverview) {
        return <WithActionButtons component={<Overview />} />
    };
    if (displayCompleteness) {
        return <WithActionButtons component={<Completeness />} />
    };
    if (displayCoverage) {
        return <WithActionButtons component={<Coverage />} />
    };
    if (displayInvoice) {
        return <WithActionButtons component={<Invoice />} />
    };

    if (displayPhoto) {
        return <WithActionButtons component={<Photo data={photo} photoPreview={photoPreview} />} />
    };
    if (displayAttachment) {
        return <WithActionButtons component={<Attachment data={attachment} attachmentPreview={attachmentPreview} />} />
    };
    if (displayMessage) {
        return <WithActionButtons component={<Message />} />
    };
    if (displayFnol) {
        return <WithActionButtons component={<FnolAttachment data={attachment} attachmentPreview={attachmentPreview} />} />
    };
    if (displayPoliceReport) {
        return <WithActionButtons component={<PoliceReportAttachment data={attachment} attachmentPreview={attachmentPreview} />} />
    };

    return (
        <Box sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography noWrap color={theme.palette.text.primary}>Keine Ansicht ausgewählt.</Typography>
        </Box>
    );

}), (prevProps, nextProps) => isEqual(prevProps.data, nextProps.data));
export default ViewHandler;