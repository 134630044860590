import { SvgIcon, useTheme } from "@mui/material";

const TriangleIcon = () => {

    const theme = useTheme();

    return (
        <SvgIcon sx={{ fontSize: 18 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                <path d="M16.5306 8.46986L9.03061 0.969863C8.96095 0.90013 8.87823 0.844811 8.78719 0.807068C8.69614 0.769324 8.59854 0.749899 8.49998 0.749899C8.40142 0.749899 8.30382 0.769324 8.21278 0.807068C8.12173 0.844811 8.03901 0.90013 7.96936 0.969863L0.469355 8.46986C0.364347 8.57475 0.292821 8.70844 0.263833 8.85401C0.234846 8.99957 0.249699 9.15046 0.306513 9.28758C0.363328 9.4247 0.459548 9.54188 0.582994 9.62428C0.706439 9.70668 0.851559 9.7506 0.99998 9.75049H16C16.1484 9.7506 16.2935 9.70668 16.417 9.62428C16.5404 9.54188 16.6366 9.4247 16.6934 9.28758C16.7503 9.15046 16.7651 8.99957 16.7361 8.85401C16.7071 8.70844 16.6356 8.57475 16.5306 8.46986Z" fill={theme.palette.mode === "dark" ? theme.palette.text.secondary : theme.palette.widget.triangleIcon} fillOpacity="0.25" />
            </svg>
        </SvgIcon>
    );

};

export default TriangleIcon;