import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

const CustomActionBar = props => {
    const { onAccept, onCancel, actions, className } = props;

    const theme = useTheme();

    if (actions == null || actions.length === 0) {
        return null;
    }

    return (
        <DialogActions className={className}>
            <Button
                variant="contained"
                disableElevation
                onClick={() => {
                    onCancel();
                }}
                sx={{
                    textTransform: "none",
                    bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                    "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                }}
            >Abbrechen</Button>
            <Button
                variant="contained"
                disableElevation
                onClick={() => {
                    onAccept();
                }}
                sx={{
                    textTransform: "none",
                    bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main",
                    "&:hover": { bgcolor: theme.palette.mode === "dark" ? "primary.light" : "primary.main" }
                }}
            >Bestätigen</Button>
        </DialogActions>
    );

};

export default CustomActionBar;