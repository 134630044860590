import CheckIcon from '@mui/icons-material/Check';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Avatar, Box, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

const PoliceReportAttachment = memo(({ data, attachmentPreview }) => {

    const theme = useTheme();
    const belowsm = useMediaQuery(theme.breakpoints.down("sm"));
    const belowmd = useMediaQuery(theme.breakpoints.down("md"));
    const below818 = useMediaQuery('(max-width:818px)');

    const { t } = useTranslation();

    const [attachment, setAttachment] = useState();
    const [preview, setPreview] = useState([]);

    useEffect(() => {
        if (data) {
            setAttachment(data);
        }
        if (attachmentPreview) {
            setPreview(attachmentPreview);
        }
        //eslint-disable-next-line
    }, [data, attachment]);

    const checkInput = (input, notApplicable) => {
        if (input && input?.length > 0) {
            return input;
        };
        if (notApplicable) {
            return "Nicht anwendbar";
        };
        return "Nicht gefunden";
    };

    const Preview = () => {

        return (
            <Grid container sx={{ display: preview?.length > 0 ? "flex" : "none", justifyContent: below818 ? "center" : "flex-start" }}>
                {preview?.map((p, i) => {
                    return <Grid item key={i} sx={{ maxWidth: belowsm ? "100%" : "300px", m: .5, border: belowsm ? 0 : 1, borderTop: belowsm && i === 0 ? 0 : 1, borderRadius: belowsm ? "0px" : "4px", borderColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider" }}>
                        <img alt={`preview-${i}`} src={p} style={{ borderRadius: belowsm ? "0px" : "4px", width: "100%" }} />
                    </Grid>
                })}
            </Grid>
        );
    };

    return (
        <Grid container direction={belowsm ? "row" : "column"} sx={{ height: belowsm ? "calc(100vh - 237px)" : "auto", overflow: "auto" }}>
            <Grid item xs={12} sx={{ pb: .5 }}>
                <Paper
                    elevation={2}
                    sx={{
                        pb: 1,
                        background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                        borderRadius: "14.362px",
                        boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                    }}
                >
                    <Typography
                        noWrap
                        sx={{
                            color: theme.palette.widget.main,
                            fontSize: belowsm ? "20px" : "24px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            mt: belowsm ? .75 : .5,
                            p: 1,
                            borderBottom: 1,
                            borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                        }}
                    >
                        {attachment?.filename}
                    </Typography>
                    <Box sx={{ p: .5 }}>
                        <Preview />
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} sx={{ pb: 2 }}>
                <Paper
                    elevation={2}
                    sx={{
                        pb: 1,
                        background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                        borderRadius: "14.362px",
                        boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                    }}
                >
                    <Typography
                        noWrap
                        sx={{
                            color: theme.palette.widget.main,
                            fontSize: belowsm ? "20px" : "24px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            mt: belowsm ? .75 : .5,
                            p: 1,
                            borderBottom: 1,
                            borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                        }}
                    >
                        {t("documentContent")}
                    </Typography>
                    <Box sx={{ p: 1 }}>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Grid container columnGap={belowsm ? 0 : 2}>
                                    <Grid item xs={belowsm ? false : "auto"} zeroMinWidth>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0 }}>Versicherte*r:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.insuredName)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0 }}>Versichertenaddresse:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.insuredAddress)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0 }}>Risiko Ort:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.riskLocation)}</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.insuredName)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.insuredAddress)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.riskLocation)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container columnGap={belowsm ? 0 : 2}>
                                    <Grid item xs={belowsm ? false : "auto"} zeroMinWidth>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0 }}>Schadendatum:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.lossDate)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0 }}>Schadenuhrzeit:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.lossTime)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0 }}>Schadensort:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.lossLocation)}</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.lossDate)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.lossTime)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.lossLocation)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ mt: belowsm ? 0 : 2 }}>
                                <Grid container columnGap={belowsm ? 0 : 2}>
                                    <Grid item xs={belowsm ? false : "auto"} zeroMinWidth>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0, width: "171px" }}>Referenznummer:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.referenceNumber)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowsm ? 1 : 0, width: "171px" }}>Zeuge*in:</Typography>
                                        <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.witness)}</Typography>
                                    </Grid>
                                    <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.referenceNumber)}</Typography>
                                        <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.witness)}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Grid container>
                                    <Grid item>
                                        <Avatar sx={{ height: 20, width: 20, bgcolor: attachment?.related ? "success.main" : "error.main", mr: 2, mt: .2 }}>
                                            {attachment?.related ? <CheckIcon sx={{ color: "#fff", fontSize: "16px" }} /> : <PriorityHighIcon sx={{ color: "#fff", fontSize: "12px" }} />}
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs zeroMinWidth>
                                        <Typography noWrap={belowsm ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{attachment?.related ? "Anhang passt zur Schadenbeschreibung" : "Anhang passt nicht zur Schadenbeschreibung"}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ my: 1 }}>
                                <Box sx={{ maxHeight: belowmd ? belowsm ? null : "84px" : "60px", overflow: belowsm ? "visible" : "auto" }}>
                                    <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{attachment?.description?.length > 0 ? attachment?.description : ""}</Typography>
                                </Box>
                            </Grid>
                            <Grid container columnGap={belowsm ? 0 : 2} sx={{ mt: 1 }}>
                                <Grid item>
                                    <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>Begründung:</Typography>
                                    <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowsm ? "block" : "none" }}>{checkInput(attachment?.relatedReasoning)}</Typography>
                                </Grid>
                                <Grid item xs zeroMinWidth sx={{ display: !belowsm ? "block" : "none" }}>
                                    <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.relatedReasoning)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={12} sx={{ display: attachment?.hasPayment ? "block" : "none" }}>
                <Box sx={{ height: belowsm ? "auto" : "100%", pb: 2 }}>
                    <Paper
                        elevation={2}
                        sx={{
                            pb: 1,
                            background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                            borderRadius: "14.362px",
                            boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                        }}
                    >
                        <Typography
                            noWrap
                            sx={{
                                color: theme.palette.widget.main,
                                fontSize: belowsm ? "20px" : "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                mt: belowsm ? .75 : .5,
                                p: 1,
                                borderBottom: 1,
                                borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                            }}
                        >
                            {t("payment")}
                        </Typography>
                        <Box sx={{ p: 1 }}>
                            <Grid container>
                                <Grid item xs={12} zeroMinWidth>
                                    <Grid container columnGap={belowmd ? 0 : 2}>
                                        <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>IBAN:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.paymentIban)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>Zahlungsempfänger*in:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.paymentRecipient, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>Betrag laut Meldung:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.paymentAmount, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>Zahlungsgrund / Referenz:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.paymentInfo, true)}</Typography>
                                        </Grid>
                                        <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.paymentIban, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.paymentRecipient, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.paymentAmount, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.paymentInfo, true)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ display: attachment?.hasCar ? "block" : "none" }}>
                <Box sx={{ height: belowsm ? "auto" : "100%", pb: 2 }}>
                    <Paper
                        elevation={2}
                        sx={{
                            pb: 1,
                            background: theme.palette.mode === "light" ? theme.palette.widget.paper : "background.paper",
                            borderRadius: "14.362px",
                            boxShadow: theme.palette.mode === "light" ? "0px 3.084px 3.084px 0px rgba(0, 0, 0, 0.25)" : "none"
                        }}
                    >
                        <Typography
                            noWrap
                            sx={{
                                color: theme.palette.widget.main,
                                fontSize: belowsm ? "20px" : "24px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                mt: belowsm ? .75 : .5,
                                p: 1,
                                borderBottom: 1,
                                borderBottomColor: theme.palette.mode === "light" ? theme.palette.widget.divider : "divider"
                            }}
                        >
                            {t("car")}
                        </Typography>
                        <Box sx={{ p: 1 }}>
                            <Grid container>
                                <Grid item xs={12} sm={6} zeroMinWidth sx={{ pr: belowmd ? 0 : 1 }}>
                                    <Grid container columnGap={belowmd ? 0 : 2}>
                                        <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>Kennzeichen:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carLicensePlate, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>Fahrgestellnummer:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carVin, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>Baujahr:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carYear, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>Kilometerstand:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carMileage, true)}</Typography>
                                        </Grid>
                                        <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carLicensePlate, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carVin, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carYear, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carMileage, true)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} zeroMinWidth sx={{ pr: belowmd ? 0 : 1 }}>
                                    <Grid container columnGap={belowmd ? 0 : 2}>
                                        <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>Marke:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carMake, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>Modell:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carModel, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0 }}>Farbe:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carColor, true)}</Typography>
                                        </Grid>
                                        <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carMake, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carModel, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carColor, true)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} zeroMinWidth sx={{ mt: belowmd ? 0 : 1 }}>
                                    <Grid container columnGap={belowmd ? 0 : 2}>
                                        <Grid item xs={belowmd ? null : "auto"} zeroMinWidth>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>Fahrzeugschaden:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carDamage, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>Fahrzeugschadenort:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carDamageLocation, true)}</Typography>
                                            <Typography noWrap color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ flexGrow: belowmd ? 1 : 0, width: "166px" }}>Fahrzeugort:</Typography>
                                            <Typography color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"} sx={{ display: belowmd ? "block" : "none" }}>{checkInput(attachment?.carLocation, true)}</Typography>
                                        </Grid>
                                        <Grid item xs zeroMinWidth sx={{ display: !belowmd ? "block" : "none" }}>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carDamage, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carDamageLocation, true)}</Typography>
                                            <Typography noWrap={belowmd ? false : true} color={theme.palette.mode === "light" ? theme.palette.widget.contrastText : "text.primary"}>{checkInput(attachment?.carLocation, true)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    );
});

export default PoliceReportAttachment;